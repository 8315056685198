<!--  
 * @author Lucas Mello <lucas.mello@icaromediagroup.com>
 * @company IcaroMediaGroup.com
 */ -->

<template>
  <div v-if="currentTime" class="container-clip">
    <div class="flex-clip">
      <div class="clip-component">
  <div class="button-container">
    <button
      @click="callPlayer(name.value)"
      v-for="(name, index) in this.$options.buttonNames"
      :key="index"
      class="resize-button"
    >
      {{ name.name }}
    </button>
  </div>
        <div>
          <p class="field-title"><cog-icon />Settings</p>
        </div>
        <div class="select-container">
          <div class="mobile-col">
            <span>Duration
              <select id="duration" v-model="value.selectedDuration" @change="handleDurationChange">
                <option
                  v-for="(duration, index) in this.$options.durations"
                  :key="index"
                  :value="duration"
                >
                  {{ duration }} seconds
                </option>
              </select>
            </span>
          </div>

          <div class="timer-class">
            <button
            class="button-timer"
            @click="callPlayer('pause')"
            contenthover="click to set clip start time" > Start time</button>         
            <input
              type="text"
              class="time-input"
              v-model="currentTime"
              placeholder="Enter time (hh:mm:ss)"
              readonly
            />
          </div>
        </div>

        <div class="input-error-message" v-if="!canClip">Your clip duration is longer than the end time</div>
        <p class="field-title"><scissors-icon />Quick Clips</p>
        <div class="button-container-clip">
          <button
            class="button"
            v-for="(name, index) in this.$options.buttonTags"
            :key="index"
             :class="{ selected: selectedButton === index }"
            @click="selectButton(index, name)"
          >
            {{ name }}
          </button>
        </div>
        <p v-if="inputTagIsEmpty" class="input-error-message">
          Please select the tag.
          </p>
        <div class="">
          <p class="field-title"><hammer-icon />Manual Clip</p>
          <span class="clip-display">Clip Name </span>
          <input type="text" placeholder="Enter clip name" v-model="value.inputText" class="input-text" />
          <button v-if="canClip" @click="onCutButton(value)" class="button-cut">
            Cut clip
          </button>
          <p v-if="inputIsEmpty" class="input-error-message">
          Please fill in the input field.
          </p>
        </div>
      </div>
      <clips-displayed class="clips-list" :my-array="returnClips" />
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../utils/event-bus';

import { mapState, mapGetters } from 'vuex';
import scissorsIcon from '@/assets/img/scissors';
import hammerIcon from '@/assets/img/hammer';
import cogIcon from '@/assets/img/cog';
import ClipsDisplayed from '@/components/clip/ClipsDisplayed';

export default {
  buttonNames: [
        {
          name: '<<10',
          value: 10,
        },
        {
          name: '<<5',
          value: 5,
        },
        {
          name: '<<1',
          value: 1,
        },
        {
          name: '||',
          value: 'pause',
        },
        {
          name: '>>1',
          value: -1,
        },
        {
          name: '>>5',
          value: -5,
        },
        {
          name: '>>10',
          value: -10,
        },
      ],
      buttonTags: [
        'Shot',
        'Save',
        'Goal',
        'Highlight',
        'Faceoff',
        'Hit',
        'Penalty',
        'Other',
      ],
      durations: [15, 30, 45, 60, 120, 180],
  name: 'ClipComponent',
  components: { scissorsIcon, hammerIcon, ClipsDisplayed, cogIcon },
  data() {
    return {     
      selectedButton: null,
      currentTime: '',
      canClip: true,
      stateTime: 0,
      stateDuration: 0,
      value: { selectedDuration: 30, selectedTime: 0, inputText: '', tag: '' },
      inputIsEmpty: false,
      inputTagIsEmpty: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters({
      clips: 'getClips',
    }),
    returnClips() {
  return this.clips ? this.clips : false;
}
  },
  created() {
    EventBus.$on('current-time-updated', this.handleCurrentTimeUpdated);
  },
  beforeMount() {
      this.$store.dispatch('GET_ALL_ID_CLIPS', { id: this.$route.params.id });
  },
  beforeDestroy(){
    EventBus.$off('current-time-updated')
  },
  methods: {
    // updateInputText(name){
      
    // },
    selectButton(index, name) {
      this.selectedButton = index;
      this.value.tag = name
    },
    handleCurrentTimeUpdated(playerTime) {
      this.currentTime = this.formatTime(playerTime.currentTime);
      this.stateTime = playerTime.currentTime
      this.stateDuration = Math.abs(playerTime.duration)
      this.handleDurationChange()
    },
    handleDurationChange() {
      this.canClip = this.stateTime + this.value.selectedDuration >= this.stateDuration ? false : true;
  },
    formatTime(timeInSeconds) {
      const hours = Math.floor(timeInSeconds / 3600);
      const minutes = Math.floor((timeInSeconds % 3600) / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = seconds.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    },
    onCutButton:  _.debounce(function(value) { 
    if (value.inputText === '') {
      this.inputIsEmpty = true
    } else {
      this.inputIsEmpty = false
    }
    if (this.value.tag === '') {
      this.inputTagIsEmpty = true
    } else {
      this.inputTagIsEmpty = false
    }
    if(this.value.tag !== '' && value.inputText !== '') {
      this.inputIsEmpty = false
      this.inputTagIsEmpty = false
      EventBus.$emit('cut', value);
      this.value.inputText = ''
    }
    }, 500),
    callPlayer(value) {
      if (value === 'pause') {
        EventBus.$emit('pause');
      } else if (value) {
        EventBus.$emit('rewind', value);
      }
    },
    playVideo() {
      EventBus.$emit('play');
    },
  },
};
</script>

<style scoped>
.button-timer{
  position: relative;
  display: inline-block;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border: none;
  background-color: #1b89ff;
  color: #fff;
  cursor: pointer;
}

.button-timer:hover {
  background-color: #006ae6;
}
@media only screen and (min-width: 600px) {
.button-timer[contenthover]:hover::after {
  content: attr(contenthover);
  position: absolute;
  top: -130%;
  left: 0;
  font-size: 12px;
  color: black;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
}
.clip-component {
  font-weight: 700;
}
.time-input {
  width: 90px;
}

.field-title {
  margin-top: 20px;
  font-size: 20px;
}
.container-clip {
  padding-top: 20px;
  text-transform: none;
  text-align: left;
  font-style: normal;
  display: flex;
  max-width: 98vw;
  margin: 0 auto;
}
.flex-clip {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-grow: 2.5;
  max-width: 70vw;
  gap: 30px;
  /* Additional styles for the flex container */
}
.clips-list {
  margin-right: auto;
}
.clip-component {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.button-container-clip {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates 4 columns */
  grid-template-rows: repeat(2, 1fr); /* Creates 2 rows */
  column-gap: 3px; /* Sets the gap between items */
  row-gap: 20px; /* Sets the gap between items */
  justify-items: center; /* Centers items horizontally within each grid cell */
  align-items: center; /* Centers items vertically within each grid cell */
  
}

.button-container-clip button {
  width: 100%; /* Makes all items the same width */
  height: 100%; /* Makes all items the same height */
  margin: 5px; /* Sets the spacing around each item */
}

.button-container-clip button.selected {
  background-color: blue; /* Add your selected button styling here */
  color: white;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;
  margin-bottom: 26px;
  overflow: hidden;
}

.button-container button {
  margin: 5px;
  padding: 10px;
  background-color: gray;
  color: black;
  font-weight: 700;
  border: none;
  cursor: pointer;
  flex-shrink: 1;
}

.select-container {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  font-weight: 600;
  gap:10px;
}


/* @media only screen and (min-width: 600px) {

  .select-container div:nth-child(odd) {
  margin-right: auto;
}

}

.select-container div:nth-child(even) {
  margin-left: auto;
} */




.select-container input {
  text-align: center;
  margin-left: 7px;
  padding: 4px;
  border: 1px solid gray;
  cursor: pointer;
}

svg {
  margin-right: 5px;
}


label {
  font-weight: bold;
  margin-bottom: 5px;
}

select {
  padding: 4px;
  border: 1px solid gray;
  cursor: pointer;
}



.input-text {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 220px;
}

.button {
  padding: 2px;
  border: none;
  background-color: #1b89ff;
  color: #fff;
  cursor: pointer;
}

.button-cut {
  padding: 8px 22px;
  border: none;
  background-color: #1b89ff;
  color: #fff;
  cursor: pointer;
}

.button-cut:hover {
  background-color: #006ae6;
}

.button:hover {
  background-color: #006ae6;
}

.input-error-message{
 color:red;
 margin-right: 22px;
 text-align: center;
}

@media only screen and (max-width: 600px) {

  .button-container button {
  margin: 3px;
  padding: 6px;
}
  .clip-display {
    display: none;
  }
  .flex-clip {
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 2.5;
  max-width: 90vw;
  gap: 10px;
  /* Additional styles for the flex container */
}
}

</style>
