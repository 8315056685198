<template>
  <div>
    <button-component
        v-if="!loading"
        @btn-click="downloadHandler"
    >
      {{title || $t('Download')}}
    </button-component>
    <Loader v-else/>
  </div>
</template>

<script>
import ButtonComponent from "@/components/base-components/buttons/ButtonComponent";
import Loader from "@/components/Loader";
import {mapMutations} from "vuex";

export default {
  name: "DownloadButton",
  components: {Loader, ButtonComponent},
  props:{
    title: {
      type: String,
    },
    url:{
      type: String,
    },
    apiMethod:{
      type: Function,
    },
    id:{
      type: Number,
    }
  },
  data(){
    return {
      loading: false,
    }
  },
  methods:{
    ...mapMutations(['ADD_TOAST']),
    errorToast(){
      this.ADD_TOAST({
        text: `Something went wrong`,
        time: 7000,
        type: 'error',
      })
    },
    downloadHandler(){
      this.loading = true;

      if (this.url){
        this.createDownload(this.url)
      } else {
        this.apiMethod({id: this.id})
            .then(res => {
              this.createDownload(res)
            })
            .catch(err => {
              this.loading = false;
              this.errorToast();
            })
      }
    },
    createDownload(url){
      try{
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', `download`)
        a.click()
        a.remove()
      } catch (err){
        this.errorToast();
      }
      this.loading = false;

    }
  }
}
</script>

<style scoped>

</style>