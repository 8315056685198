<template>
  <section class="mb-4 mainParticipantsContainer">
    <div class="d-flex">
      <div>
        <div class="team-img" v-if="teams.awayTeam.img">
          <ImageComponent
            :path="teams.awayTeam.img"
            :alt="teams.awayTeam.name"
          />
        </div>
        <div class="d-flex">
          <span :class="['my-auto', { 'mx-auto': !isLogged }]">
            {{ teams.awayTeam.name }}
          </span>
          <div
            v-if="isLogged"
            @click="awayFavClick"
            class="ml-2 cursor-pointer"
          >
            <StarIcon :width="26" :height="26" :boolean="awayFavorite" />
          </div>
        </div>
      </div>
      <div class="my-auto versus">@</div>
      <div>
        <div class="team-img" v-if="teams.homeTeam.img">
          <ImageComponent
            :path="teams.homeTeam.img"
            :alt="teams.homeTeam.name"
          />
        </div>
        <div class="d-flex">
          <span :class="['my-auto', { 'mx-auto': !isLogged }]">
            {{ teams.homeTeam.name }}
          </span>
          <div
            v-if="isLogged"
            @click="homeFavClick"
            class="ml-2 cursor-pointer"
          >
            <StarIcon :width="26" :height="26" :boolean="homeFavorite" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ImageComponent from '@/components/base-components/ImageComponent';
import StarIcon from '@/components/icons/Star';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'VideoScreenViewParticipants',
  components: {
    ImageComponent,
    StarIcon,
  },
  data() {
    return {
      homeFavorite: false,
      awayFavorite: false,
    };
  },
  props: {
    teams: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getFavorites: 'getFavorites',
    }),
    isLogged() {
      return this.$store.getters.isLogged;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions([
      'POST_ADD_FAVORITE',
      'GET_DELETE_FAVORITE',
      'GET_USER_FAVORITES',
    ]),
    async initialize() {
      if (this.getFavorites.length === 0) {
        await this.GET_USER_FAVORITES();
      }
      this.homeFavorite = this.getFavorites.some(
        (favorite) => favorite.favorite_id === this.teams.homeTeam.team_id
      );
      this.awayFavorite = this.getFavorites.some(
        (favorite) => favorite.favorite_id === this.teams.awayTeam.team_id
      );
    },
    homeFavClick() {
      if (this.isLogged) {
        this.homeFavorite = !this.homeFavorite;
        // fire save favorite api
        if (this.homeFavorite) {
          this.POST_ADD_FAVORITE({
            favorite_id: this.teams.homeTeam.team_id,
            name: this.teams.homeTeam.name,
            favorite_type: 'participant',
          });
        } else {
          this.GET_DELETE_FAVORITE({
            favorite_type: 'participant',
            id: this.teams.homeTeam.team_id,
          });
        }
      }
    },
    awayFavClick() {
      if (this.isLogged) {
        this.awayFavorite = !this.awayFavorite;
        if (this.awayFavorite) {
          this.POST_ADD_FAVORITE({
            favorite_id: this.teams.awayTeam.team_id,
            name: this.teams.awayTeam.name,
            favorite_type: 'participant',
          });
        } else {
          this.GET_DELETE_FAVORITE({
            favorite_type: 'participant',
            id: this.teams.awayTeam.team_id,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.team-img {
  width: 90px;
  height: 90px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.mainParticipantsContainer {
  text-align: center;
}
.versus {
  font-size: 1.85rem;
  font-weight: 600;
  margin-left: 2rem;
  margin-right: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
