<template>
  <blocked-content-wrapper :blocked="blocked" class="home-container mb-5">
    <static-block-component page="event" position="top" />
    <div v-if="event && event.participants">
      <div
        class="d-flex justify-content-center mt-3 align-items-center teams-images-wrapper"
      >
        <template v-if="event.participants.length === 2">
          <Participants :teams="teams" />
        </template>
        <template v-else>
          <div
            class="img-wrapper"
            :class="{
              small: event.stream_status !== $options.STREAM_STATUS.upcoming,
            }"
            @click.stop.prevent="
              embeddedPreview && !showEmbeddedPreview && togglePreview(true)
            "
          >
            <image-component
              v-if="eventImage"
              class="event-img"
              :path="eventImage"
              alt="banner"
            />
            <button-component
              v-if="embeddedPreview && !showEmbeddedPreview"
              variant="icon"
              class="play-preview"
              @btn-click="togglePreview(true)"
            >
              <i class="fas fa-play" />
            </button-component>
          </div>
          <div class="font-weight-bold pt-2 h4 m-0">
            {{ this.videoTitle }}
          </div>
        </template>
      </div>
      <span v-if="category" class="h4 font-weight-bold">{{ category }}</span>
      <div class="py-2 d-flex">
        <div class="m-auto d-flex">
          <CalendarIcon />
          <span class="ml-2">
            {{ this.getMonthAndDay(event.event_utc_ts) }}
            &#183;
            {{ this.getHoursAndMinutes(event.event_utc_ts) }}
          </span>
        </div>
      </div>
      <p v-if="event.caption">{{ event.caption }}</p>
      <div v-if="loading">
        <loader />
      </div>
      <div v-else class="player_wrapper">
        <div class="container" v-if="!event.is_geo_blocked">
          <template v-if="eventUpcoming">
            <div class="flip-section">
              <p>{{ $t('Broadcast starts in') }}:</p>
              <flip
                @timeEnded="onTime"
                :timestamp="event.event_utc_ts"
                :countDownPreview="countDownPreview"
              />
            </div>
          </template>
          <template v-else>
            <video-player
              v-if="!embedded"
              :options="videoOptions"
              :analytics-options="analyticsOptions"
            />
            <div v-else v-html="embedded"></div>
            <div class="actions-wrapper">
              <button-component
                @btn-click="toggleScore"
                class="score-toggle-btn"
                v-if="scoreEnabled"
              >
                {{ $t(`${showScore ? 'Hide' : 'Show'} Score`) }}
              </button-component>
              <button-component
                @btn-click="toggleChat"
                class="chat-toggle-btn"
                v-if="chatIsEnabled"
              >
                {{ $t(`${chatDisplayed ? 'Hide' : 'Show'} chat`) }}
              </button-component>
            </div>
            <download-button
              v-if="event.can_download"
              :api-method="DOWNLOAD_EVENT"
              :id="event.id"
              class="mt-3 d-flex"
            />
          </template>
        </div>
        <template v-if="chatIsEnabled">
          <div v-show="chatDisplayed" class="chat">
            <chat-component :event="event" />
          </div>
        </template>
      </div>
      <ClipComponent
        v-if="hasCreateClipCapability && upcomingStatus !== 'upcoming'"
      />
    </div>
    <div v-show="scoreEnabled && showScore" class="player_wrapper mt-5">
      <div class="container" v-html="scoreEnabled"></div>
    </div>
    <static-block-component page="event" position="bottom" />
    <div
      v-if="showEmbeddedPreview"
      class="embeddeb-preview"
      @click.stop.prevent="togglePreview(false)"
    >
      <div class="content">
        <button-component
          variant="icon"
          class="closeIcon"
          @btn-click="togglePreview(false)"
        >
          <XMarkIcon />
        </button-component>
        <embedded-player
          :link="embeddedPreview"
          :autoplay="true"
          class="preview-player"
        />
      </div>
    </div>
  </blocked-content-wrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  COMING,
  MEDIA_TYPE,
  MEDIA_TYPE_NAME_BY_ID,
  OUTER_URLS,
  STREAM_STATUS,
} from '../../const';
import VideoPlayer from '@/components/video/VideoPlayer';
import StaticBlockComponent from '@/components/StaticBlockComponent';
import Flip from '@/components/video/Flip';
import ClipComponent from '@/components/clip/ClipComponent';
import ChatComponent from '@/league/components/ChatComponent';
import ImageComponent from '@/components/base-components/ImageComponent';
import BlockedContentWrapper from '@/components/base-components/BlockedContentWrapper';
import ButtonComponent from '@/components/base-components/buttons/ButtonComponent';
import XMarkIcon from '@/assets/img/XMarkIcon';
import EmbeddedPlayer from '@/components/base-components/ebmeddedPlayer/embeddedPlayer';
import Loader from '@/components/Loader';
import DownloadButton from '@/components/base-components/buttons/DownloadButton';
import CalendarIcon from '@/components/icons/Calendar';
import Participants from '@/components/video/Participants';
import { getAwayHomeTeams } from '@/utils/helpers';

export default {
  name: 'WatchGameComponent',
  components: {
    DownloadButton,
    Loader,
    EmbeddedPlayer,
    XMarkIcon,
    ButtonComponent,
    ClipComponent,
    BlockedContentWrapper,
    ImageComponent,
    ChatComponent,
    Flip,
    VideoPlayer,
    StaticBlockComponent,
    CalendarIcon,
    Participants,
  },
  data() {
    return {
      event: {},
      videoOptions: {
        autostart: true,
        file: null,
        preRoll: null,
      },
      analyticsOptions: {},
      showEmbeddedPreview: false,
      chatDisplayed: true,
      loading: false,
      showScore: false,
    };
  },
  STREAM_STATUS: STREAM_STATUS,
  methods: {
    ...mapActions(['GET_EVENT', 'DOWNLOAD_EVENT', 'GET_EVENT_CATEGORIES']),
    getMediaByType(type) {
      return this.event.media.find(
        (item) => Number(item.media_type) === Number(type)
      );
    },
    getMediaPropFromType(mediaType, prop) {
      const media = this.getMediaByType(mediaType);
      return prop ? media?.[prop] : media;
    },
    onTime() {
      const tenSec = 10 * 1000;
      const randomCount = Math.round(Math.random() * tenSec);
      setTimeout(() => this.getEventInfo(), randomCount);
      this.loading = true;
    },
    getEventInfo() {
      this.loading = true;
      this.GET_EVENT(this.$route.params.id)
        .then((data) => {
          const event = data.data;
          if (!event.has_access && !event.is_geo_blocked) {
            if (this.$store.getters.isLogged) {
              let path = '/select-package/' + data.data.id;
              this.$router.replace({ path });
            } else {
              this.$router.push({ name: 'login' });
            }
            return;
          }
          this.event = event;
        })
        .catch((data) => {
          if (data.status == 404) {
            this.$router.push({ name: 'ErrorPage' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    togglePreview(show) {
      this.showEmbeddedPreview = show;
      document.querySelector('body').classList.toggle('overflow-hidden', show);
    },
    toggleChat() {
      this.chatDisplayed = !this.chatDisplayed;
    },
    toggleScore() {
      this.showScore = !this.showScore;
    },
  },
  watch: {
    event() {
      if (this.blocked) return;

      const thumbnails = this.getMediaPropFromType(
        MEDIA_TYPE.thumbnails,
        'media_url'
      );
      if (thumbnails) {
        this.videoOptions.tracks = [
          {
            file: thumbnails,
            kind: 'thumbnails',
          },
        ];
      }
      this.videoOptions.file = this.getMediaPropFromType(
        this.streamStatus,
        'media_url'
      );
      console.log('VIDEO_GOT_IT', this.videoOptions.file);
      if (this.event) {
        if (this.event.stream_status === this.$options.STREAM_STATUS.live) {
          if (this.event[COMING.LIVE_COMING]) {
            this.videoOptions.file = OUTER_URLS.LIVE_DELAYED;
          } else if (this.needPreRoll) {
            this.videoOptions.preRoll = OUTER_URLS.EVENT_PREROLL;
          }
        }
        if (this.event.stream_status === this.$options.STREAM_STATUS.vod) {
          if (this.event[COMING.VOD_COMING]) {
            this.videoOptions.file = OUTER_URLS.ARCHIVE_CURRENTLY_UNAVAILABLE;
          } else if (this.event[COMING.VOD_NO]) {
            this.videoOptions.file = OUTER_URLS.EVENT_UNAVAILABLE;
          } else if (this.needPreRoll) {
            this.videoOptions.preRoll = OUTER_URLS.EVENT_PREROLL;
          }
        }
      }

      this.analyticsOptions = {
        tag: this.event.stream_name,
        videoTitle: this.videoTitle,
        uniqueId: this.event.id,
        streamType:
          MEDIA_TYPE_NAME_BY_ID[
            this.getMediaPropFromType(this.streamStatus, 'media_type')
          ] || '',
      };
    },
  },
  computed: {
    ...mapState({
      channelSettings: (state) => state.channel.settings,
      categories: (state) => state.event.eventCategoriesById,
      userPermission: (state) => state.auth.user, // get user capabilities to check if he has the role to user the clipping tool
    }),
    category() {
      return this.categories?.[this.event.event_category_id]?.name;
    },
    // get user capabilities to check if he has the role to user the clipping tool
    hasCreateClipCapability() {
      if (this.userPermission)
        return this.userPermission.capabilities.some((user) =>
          user.capability.includes('create_clip')
        );
      else false;
    },
    flattenTargetArr() {
      const targetID = 78;
      const arr = [];
      const getId = (category) => {
        if (category && category.id !== undefined) {
          arr.push(category.id);
          category.children?.length && category.children.forEach(getId);
        }
      };
      const targetCategory = this.categories[targetID];
      if (targetCategory) {
        getId(targetCategory);
      }
      return arr;
    },
    needPreRoll() {
      console.log(
        'needPreRoll',
        this.flattenTargetArr.includes(this.event.event_category_id)
      );
      return this.flattenTargetArr.includes(this.event.event_category_id);
    },
    streamStatus() {
      return this.event.stream_status === STREAM_STATUS.vod
        ? MEDIA_TYPE.vod
        : MEDIA_TYPE.live;
    },
    upcomingStatus() {
      return this.event.stream_status;
    },
    embedded() {
      return this.getMediaPropFromType(MEDIA_TYPE.embedded, 'media_info');
    },
    embeddedPreview() {
      return this.getMediaPropFromType(
        MEDIA_TYPE.embedded_preview,
        'media_info'
      );
    },
    videoTitle() {
      if (!this.event || !this.event.participants) {
        return '';
      }
      if (this.event.participants.length === 2) {
        return `${this.trimParticipant(
          this.teams.awayTeam.name
        )} @ ${this.trimParticipant(this.teams.homeTeam.name)}`;
      }
      return this.event.name || '';
    },
    chatIsEnabled() {
      return (
        !this.event.is_geo_blocked &&
        this.event?.stream_status === this.$options.STREAM_STATUS.live &&
        this.event?.is_chat_enabled
      );
    },
    scoreEnabled() {
      return this.event.iframe;
    },
    eventImage() {
      return this.getMediaPropFromType(MEDIA_TYPE.image, 'media_url');
    },
    blocked() {
      return this.event?.is_geo_blocked;
    },
    countDownPreview() {
      const content = this.channelSettings.content;
      return parseInt(content['event-count-down-preview']) || 0;
    },
    teams() {
      return getAwayHomeTeams(this.event);
    },
    eventUpcoming() {
      return (
        this.event.stream_status === this.$options.STREAM_STATUS.upcoming ||
        this.event.event_utc_ts * 1000 > +new Date()
      );
    },
  },
  mounted() {
    this.getEventInfo();
    this.GET_EVENT_CATEGORIES();
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/css/mixins';
@import 'src/assets/css/colors';

.team-img {
  width: 90px;
  height: 90px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.flip-section {
  max-width: 40rem;
  margin: 5rem auto;
  text-align: left;

  p {
    margin: 1rem;
  }
}
.teams-images-wrapper {
  gap: 2rem;
}
.img-wrapper {
  max-width: calc(100vw - 1rem);
  max-height: 23rem;
  overflow: hidden;
  margin: 0 0.5rem;
  position: relative;
  @include ratio(calc(16 / 9));

  &.small {
    max-height: 10rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.player_wrapper {
  display: flex;
  max-width: 98vw;
  gap: 1.5rem;
  margin: 0 auto;

  .container {
    flex-grow: 2.5;
    max-width: calc(98vw / 3.5 * 2.5);
  }

  .chat {
    flex-grow: 1;
  }

  @include media-max(lg) {
    flex-direction: column;
    .container {
      max-width: 100%;
    }
  }
}

.play-preview {
  position: absolute;
  z-index: 10;
  width: 3rem;
  height: 3rem;
  top: 50%;
  background: $play-button-color;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.embeddeb-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba($text-primary-rgb, 0.5);
  backdrop-filter: blur(10px);
  max-height: 100vh;
}

.content {
  position: relative;
  padding: 4rem;
  max-height: inherit;
}

.closeIcon {
  display: block;
  margin-left: auto;
  width: 3rem;
  height: 3rem;
  padding: 0.7rem;
}

.chat-toggle-btn,
.score-toggle-btn {
  display: block;
  padding: 0.5rem 2rem;
}
.actions-wrapper {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
</style>
<style lang="scss">
.preview-player {
  max-width: 99vw;
  position: relative;

  iframe {
    max-width: 95vw;
  }
}
</style>
